import React from "react";
import Home from "../components/course";

const course = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default course;
