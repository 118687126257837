import React from "react";
import * as styles from "./course.module.css";

const CourseHead = () => {
  return (
    <div className={styles.mainSection}>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <h1>
            <span>Courses</span>
          </h1>
          <h3>Level up as a developer. Monetize your programming skills.</h3>
        </div>
      </div>
    </div>
  );
};

export default CourseHead;
