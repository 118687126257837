import React from "react";
import Footer from "../home/footer/Footer";
import Navbar from "../home/navbar/Navbar";
import Courses from "../home/courses/Courses";
import Coursehead from "./Coursehead";
import SEO from "../Seo";
import * as styles from "./course.module.css";

const index = () => {
  return (
    <div className={styles.componentWrapper}>
      <SEO titleStub={`Courses`} urlStub={`/courses/`} />
      <Navbar />
      <Coursehead />
      <Courses />
      <Footer />
    </div>
  );
};

export default index;
